<template>
  <div class="tw-border-solid tw-border-2 tw-rounded-lg">
    <table class="tw-w-full">
      <thead class="tw-border-b tw-border-gray-500 tw-bg-gray-100 tw-p-2">
        <tr>
          <th class="tw-py-2 tw-px-2 tw-w-10 tw-text-center">
            {{ $t("stockIn.no") }}
          </th>
          <th class="tw-py-2 tw-px-2">
            {{ $t("stockIn.barcode") }}
          </th>
          <th class="tw-py-2 tw-px-2">
            {{ $t("stockIn.productName") }}
          </th>
          <th class="tw-py-2 tw-px-2">
            {{ $t("stockIn.expireDate") }}
          </th>
          <th class="tw-py-2 tw-px-2">
            {{ $t("stockIn.uom") }}
          </th>
          <!-- <th class="tw-py-2 tw-px-2">
            {{ $t("stockIn.cost") }}
          </th> -->
          <th class="tw-py-2 tw-px-2 tw-text-center">
            {{ $t("stockIn.qty") }}
          </th>
          <!-- <th class="tw-py-2 tw-px-2 tw-text-right">
            {{ $t("stockIn.amount") }}
          </th> -->
          <th class="tw-w-8 tw-py-2 tw-px-2">
            <a
              @click.prevent="addRow"
              href="#"
              class="tw-px-2 tw-py-1 tw-text-black"
              ><i class="fa fa-plus"></i
            ></a>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(product, index) in model.product_detail"
          :key="index"
          class="tw-border-b tw-w-auto"
        >
          <td class="tw-px-2 tw-text-center">{{ index + 1 }}</td>
          <td class="tw-align-top tw-py-1 tw-px-2">
            <a-input
              style="min-width: 90px"
              disabled
              class="tw-font-black"
              :value="porductBarcode(product.product_id)"
            />
          </td>
          <td class="tw-align-top tw-py-1 tw-px-2">
            <a-input
              style="min-width: 90px"
              disabled
              :value="productName(product.product_id)"
              class="tw-font-black"
            />
          </td>
          <td class="tw-align-top tw-py-1 tw-px-2">
            <a-input
              v-model="product.expiry_date"
              placeholder="Select Date"
              style="min-width: 90px"
              disabled
              :value="product.expiry_date"
            />
          </td>
          <td class="tw-align-top tw-py-1 tw-px-2">
            <a-select
              style="min-width: 100px"
              :options="getUom(product.product_id)"
              class="tw-w-full"
              v-model="product.uom_id"
              @change="onUomChange(product)"
            ></a-select>
            <div
              class="tw-text-red-600 tw-text-xs"
              v-if="errors.has('product_detail.' + index + '.uom_id')"
            >
              {{ errors.first("product_detail." + index + ".uom_id") }}
            </div>
          </td>
          <!-- <td class="tw-align-top tw-py-1 tw-px-2">
            <a-input
              type="number"
              prefix="$"
              style="min-width: 90px; text-align: right"
              v-model="product.cost"
              :value="product.cost"
            />
            <div
              class="tw-text-red-600 tw-text-xs"
              v-if="errors.has('product_detail.' + index + '.cost')"
            >
              {{ errors.first("product_detail." + index + ".cost") }}
            </div>
          </td> -->
          <td class="tw-align-top tw-py-1 tw-px-2">
            <a-input
              type="number"
              v-model.number="product.qty"
              style="min-width: 80px"
              class="tw-text-center"
              :value="product.qty"
            />
            <div
              class="tw-text-red-600 tw-text-xs"
              v-if="errors.has('product_detail.' + index + '.qty')"
            >
              {{ errors.first("product_detail." + index + ".qty") }}
            </div>
          </td>
          <!-- <td class="tw-align-top tw-py-1 tw-px-2">
            <a-input
              prefix="$"
              class="tw-font-black"
              style="min-width: 80px; text-align: right"
              disabled
              :value="product.amount"
            />
          </td> -->
          <td class="ts-text-center tw-w-8 tw-p-3">
            <a
              href="#"
              class="tw-text-red-600"
              @click.prevent="removeRow(product)"
            >
              <i class="fa fa-trash"></i>
            </a>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <td class="tw-text-red-700" colspan="2">
          <p class="tw-font-extrabold">
            {{ $t("stockIn.numberRow") + ": " }}
            <span class="tw-font-extrabold">{{
              model.product_detail.length
            }}</span>
          </p>
        </td>
        <td class="tw-text-red-700 tw-text-right tw-px-3" colspan="2">
          <p class="tw-font-extrabold">
            {{ $t("stockIn.total") + ": " }}
          </p>
        </td>
        <td class="tw-text-red-700 tw-text-right tw-px-3">
          <!-- <p class="ts-font-extrabold">{{ $currencyFormat(sumCost) }}</p> -->
        </td>
        <td class="tw-text-red-700 tw-text-center tw-px-3 tw-pr-6">
          <p class="tw-font-extrabold">
            {{ $formatNumber(qty) }}
          </p>
        </td>
        <!-- <td class="tw-text-red-700 tw-text-right tw-px-3">
          <p class="tw-font-extrabold">
            {{ $currencyFormat(amount) }}
          </p>
        </td> -->
      </tfoot>
    </table>
    <a-modal
      v-if="show_modal_add_product"
      v-model="show_modal_add_product"
      :footer="null"
      :zIndex="1020"
      :maskClosable="false"
      width="700px"
      :title="$t('stockIn.product')"
    >
      <SearchToAdd v-model="model" />
    </a-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import SearchToAdd from "./search-to-add.vue";
import { sumBy } from "lodash";
export default {
  name: "productDetails",
  props: ["value", "validate"],
  components: {
    SearchToAdd,
  },
  data() {
    return {
      errors: new Errors(),
      show_modal_add_product: false,
      amount: 0,
      qty: 0,
      model: {},
      newRow: {
        product_id: null,
        uom_id: null,
        qty: 1,
        cost: 0,
        barcode: 0,
        amount: 0,
        expiry_date: null,
      },
    };
  },
  created() {
    this.model = this.value;
  },
  computed: {
    ...mapState("inventory/stockIn", ["formModels"]),
    ...mapGetters("inventory/stockIn", ["getUom"]),
  },
  methods: {
    porductBarcode(product_id) {
      if (!this.formModels.product) return null;

      return this.formModels.product.find((bc) => bc.product_id == product_id)
        .barcode;
    },
    productName(product_id) {
      if (!this.formModels.product) return null;
      const pro = this.formModels.product.find((pn) => pn.product_id == product_id)
        return pro.product_name_en + '/' + pro.product_name_kh
    },
    amountTotal() {
      this.amount = sumBy(this.model.product_detail, function (o) {
        return o.amount;
      });
    },
    sumTotalQty() {
      this.qty = sumBy(this.model.product_detail, "qty");
    },
    addRow() {
      this.show_modal_add_product = true;
    },
    removeRow(row) {
      this.model.product_detail.splice(
        this.model.product_detail.indexOf(row),
        1
      );
      this.errors = new Errors();
    },
    calculate() {
      this.model.product_detail.forEach((el) => {
        el.amount = el.qty * el.cost;
      });
    },
    onUomChange(product) {
      if (this.formModels.product != undefined) {
        var productState = this.formModels.product.find(
          (p) => p.product_id == product.product_id
        );
        if (productState != undefined) {
          const cost = productState.product_uom.find(
            (u) => u.uom_id == product.uom_id
          ).pivot.cost;
          product.cost = cost ? parseFloat(cost) : 0;
        }
      }
    },
  },
  watch: {
    "model.product_detail": {
      handler() {
        this.calculate();
        this.amountTotal();
        this.sumTotalQty();
      },
      deep: true,
    },
    validate: function (error) {
      this.errors = new Errors(error.errors);
    },
  },
};
</script>

<style lang="scss" scoped></style>
