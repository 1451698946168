<template>
    <div
        class="tw-flex tw-justify-between tw-border-solid tw-border-2 tw-rounded-lg"
    >
        <div class="col-md-6 tw-items-center tw-space-y-3">
            <div class="row tw-flex" v-if="$whois.admin() || $whois.agency()">
                <label
                    class="
            col-md-3
            form-col-label
            control-label
            tw-text-right
            required
            tw-py-2
          "
                    >{{ $t("stockIn.shop") }}</label
                >
                <div class="col-md-7">
                    <a-select
                        show-search
                        allowClear
                        :options="shopList"
                        option-filter-prop="children"
                        class="tw-w-full"
                        v-model="model.shop_id"
                        @change="onShopChange(model.shop_id)"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('shop_id')"
                    >
                        {{ errors.first("shop_id") }}
                    </div>
                </div>
            </div>
            <div class="row tw-flex">
                <label
                    class="col-md-3 form-col-label control-label tw-text-right tw-py-2"
                    >{{ $t("stockIn.invoice") }}</label
                >
                <div class="col-md-7">
                    <a-input v-model="model.invoice_no" />
                </div>
            </div>
            <div class="row tw-flex">
                <label
                    class="col-md-3 form-col-label control-label tw-text-right tw-py-2"
                    >{{ $t("stockIn.remark") }}</label
                >
                <div class="col-md-7">
                    <a-textarea :rows="4" v-model="model.remark" />
                </div>
            </div>
        </div>
        <div class="col-md-6 tw-items-center tw-space-y-3">
            <div class="row tw-flex">
                <label
                    class="
            col-md-3
            form-col-label
            control-label
            tw-text-right
            required
            tw-py-2
          "
                    >{{ $t("stockIn.stockInDate") }}</label
                >
                <div class="col-md-7">
                    <a-date-picker
                        class="tw-w-full"
                        format="DD-MM-YYYY"
                        valueFormat="DD-MM-YYYY"
                        v-model="model.stock_in_date"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors['stock_in_date']"
                    >
                        {{ errors["stock_in_date"][0] }}
                    </div>
                </div>
            </div>
            <div class="row tw-flex">
                <label
                    class="
            col-md-3
            form-col-label
            control-label
            tw-text-right
            required
            tw-py-2
          "
                    >{{ $t("stockIn.fixRate") }}</label
                >
                <div class="col-md-7">
                    <a-input v-model="model.fix_rate" disabled />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors['fix_rate']"
                    >
                        {{ errors["fix_rate"][0] }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
export default {
    name: "purchaseOrderNumber",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("inventory/stockIn", ["formModels"]),
        shopList() {
            if (!this.formModels.shop) return [];

            return this.formModels.shop.map(el => ({
                value: el.shop_id,
                label: el.shop_name
            }));
        }
    },
    methods: {
        ...mapActions("inventory/stockIn", ["getFormViewData"]),
        fetchIfShop() {
            if (this.$whois.shop()) {
                this.onShopChange(this.$store.state.authUser.user.shop_id);
            }
        },
        onShopChange(shop_id) {
            this.fixRate(shop_id);
            this.model.product_detail = [];
            this.$emit("loading", true);
            this.getFormViewData({
              index: "product",
              params: {
                fnName: "product",
                shop_id: shop_id,
              },
            }).finally(() => this.$emit("loading", false));
        },
        fixRate(shop_id) {
            if (shop_id == null) {
                this.model.fix_rate = null;
            } else {
                let shopExchange = this.formModels.shop.find(
                  (el) => el.shop_id == shop_id
                );
                this.model.fix_rate = this.exchangeRateToKH(shopExchange.exchange_rate);
            }
        },
        exchangeRateToKH(rateList) {
            var rate = rateList.find(c => (c.currency_id_to = 2));

            if (rate != undefined) {
                return rate.fx_rate;
            }
            // if shop no exchange rate from USD to KHR then get it from JD Exchange rate
            return 4000;
        }
    },

    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
